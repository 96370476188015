.image_manager {
    flex: 1;
    margin: 30px 30px;
    min-width: 450px;
}
.upload_image {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
    align-items: center;
    background-color: rgb(7, 6, 6);
    border: solid 1px gray;
    border-radius: 10px;
}
.upload_image p{
    margin: 5px 0px;
}
.image_input {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: red;
    opacity: 0;
}
.image_input:hover {
    cursor: pointer;
}
.image_view {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.image_item {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: solid 1px pink;
    margin: 10px;
}
.image_item img{
    width: 120px;
    height: auto;
}
.selected_item {
    border: solid 1px #04d9d9;
}
.remove_img {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: 5px;
    background: #32484D;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin: 0;
    font-size: 18px;
}
.remove_img:hover {
    background: #0597F2;
    cursor: pointer;
}
.rarity_img {
    position: absolute;
    top: 2px;
    left: 2px;
    font-size: 14px;
    background-color: #04d9d988;
    border-radius: 5px;
    padding: 2px 5px;
    margin: 0;
}