.sidebar {
    width: 400px;
    margin: 30px;
}
/* Single Layer */
.layer_single {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
    padding: 5px 10px;
    background-color: #32484D;
    border: 1px solid #32484D;
    border-radius: 5px;
}
.layer_single:hover {
    cursor: pointer;
    border: 1px solid #04d9d9;
}
.layer_title {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
}
.layer_values {
    display: flex;
    color: white;
    font-size: 12px;
}
.layer_values p{
    border: 0.5px solid gray;
    border-radius: 5px;
    margin: 5px;
    padding: 3px;
}
.selected_layer {
    border-color: #0597F2;
}

/* New Layer */
.new_layer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
    padding: 5px 10px;
    background-color: #32484D;
    border: 1px solid #32484D;
    border-radius: 5px;
}
.new_layer_input {
    height: 20px;
    background-color: #282c34;
    color: white;
    padding: 3px 10px;
    margin-right: 5px;
}
.new_layer_add {
    display: flex;
    font-size: 18px;
    border: 0.5px solid white;
    border-radius: 50%;
    margin: 5px;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;
}
.new_layer_add:hover {
    cursor: pointer;
}
/* Button Group */
.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
button {
    background-color: #0597F2;
    color: white;
    height: 32px;
    border-radius: 5px;
}
button:hover {
    cursor: pointer;
}
/* Generate */
.generate_collection {
    background-color: #32484D;
    border-radius: 10px;
    color: white;
    padding: 10px;
    margin-top: 20px;
    font-size: 14px;
}
.collection_size {
    font-size: 18px;
    font-weight: 800;
}
.generate_item {
    display: flex;
    justify-content: space-between;
}
.generate_item:hover {
    cursor: pointer;
    background-color: #0597F233;
}
.generate_item p {
    margin: 5px;
}
.nft_packs {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.card_pack {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    margin-top: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 3px 3px #0597F2;
}
.card_pack p {
    padding: 5px 0px;
    margin: 0px;
}
.card_pack button {
    border: solid 2px #1b0140;
    color: white;
    padding: 5px 10px;
    font-weight: 600;
    border-radius: 15px;
}
.card_save {
    font-weight: 800;
    color: #2947f2;
}
.card_price {
    font-size: 26px;
    font-weight: 800;
    color: #059f72;
}
/* Divider */
.divider {
    height: 1px;
    width: 100%;
    background-color: gray;
    margin: 10px 0px;
}
