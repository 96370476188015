.payment_settings {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    z-index: 999;
}
.payment_modal {
    position: relative;
    width: 600px;
    height: fit-content;
    border-radius: 10px;
    padding: 80px 50px;
    background-color: #fff;
}
.parant_range {
    border-bottom: solid 2px #000000;
    padding: 0px;
}
.close_modal {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 20px;
    top: 20px;
    border: none;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: #1B014055;
    font-size: 24px;
}
.close_modal:hover {
    cursor: pointer;
    background-color: #1B0140cc;
}
.payment_options {
    display: flex;
    justify-content: center;
    align-items: center;
}
.payment_options p{
    margin: 20px 30px;
}
.selected_option {
    color: pink;
    border-bottom: solid 2px pink;
}
.payment_options p:hover {
    cursor: pointer;
    color: #1B0140ff;
}
.card_payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
}
.card_buy_button {
    width: 100px;
}
.StripeElement {
    width: 100%;
    height: 80px;
}
